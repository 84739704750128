export const truckInputs = [
    {
        id: 1,
        label: "License",
        type: "text",
        placeholder: "ABCD123",
    },
    {
        id: 2,
        label: "Driver's Name",
        type: "text",
        placeholder: "John Doe",
    },
    {
        id: 3,
        label: "Capacity",
        type: "number",
        placeholder: "12345",
    },
];

export const tripInputs = [
    {
        id: 1,
        label: "Truck",
        type: "text",
        placeholder: "ABCD123",
    },
    {
        id: 2,
        label: "Origin Facility",
        type: "text",
        placeholder: "Warehouse 1",
    },
    {
        id: 3,
        label: "Destination Facility",
        type: "text",
        placeholder: "Warehouse 2",
    },
    {
        id: 4,
        label: "Start Date",
        type: "text",
        placeholder: "01/01/1970 00:00:00",
    },
    {
        id: 5,
        label: "End Date",
        type: "text",
        placeholder: "01/01/1970 00:00:00",
    },
    {
        id: 6,
        label: "Earnings",
        type: "number",
        placeholder: 1234,
    },
];

export const facilityInputs = [
    {
        id: 1,
        label: "Facility Name",
        type: "text",
        placeholder: "Warehouse 1",
    },
    {
        id: 2,
        label: "Address",
        type: "text",
        placeholder: "123 Sesame St",
    },
    {
        id: 3,
        label: "City",
        type: "text",
        placeholder: "New York City",
    },
    {
        id: 4,
        label: "State",
        type: "text",
        placeholder: "NY",
    },
    {
        id: 5,
        label: "ZIP Code",
        type: "text",
        placeholder: "12345",
    },
];
